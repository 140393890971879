<template>
<!-- eslint-disable max-len -->
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26">
  <g id="Gruppe_461" data-name="Gruppe 461" transform="translate(-1810.102 -99.657)">
    <path id="Icon_awesome-file-csv" data-name="Icon awesome-file-csv" d="M10.5,6.375V0H1.125A1.122,1.122,0,0,0,0,1.125v21.75A1.122,1.122,0,0,0,1.125,24h15.75A1.122,1.122,0,0,0,18,22.875V7.5H11.625A1.128,1.128,0,0,1,10.5,6.375ZM6,13.125a.375.375,0,0,1-.375.375H5.25c-.414,0-.75.336-.75,2.25,0,.414.336.75,1.125.75.207,0,.375.168.375,1.125,0,.207-.168.375-.75.375C4.007,18,3,16.993,3,14.25A2.359,2.359,0,0,1,5.625,12C5.832,12,6,12.168,6,13.125ZM8.075,18H7.5c-.207,0-.375-.168-.375-1.125A.375.375,0,0,1,7.5,16.5h.575c.279,0,.453-.435.389-.49l-1.026-.88a1.74,1.74,0,0,1-.625-1.319c0-1,.892-1.81,2.562-1.81a.375.375,0,0,1,.375.375v.75c0,.207-.168.375-.95.375-.279,0-.488.164-.488.31a.248.248,0,0,0,.1.18c1.423,1.218,1.651,1.7,1.651,2.2A1.906,1.906,0,0,1,8.075,18ZM12,12.375v.975a10.077,10.077,0,0,0,.75,2.666,5.108,5.108,0,0,0,.75-2.666v-.975A.375.375,0,0,1,13.875,12h.75a.375.375,0,0,1,.375.375v.975a6.447,6.447,0,0,1-1.7,4.41.75.75,0,0,1-1.1,0,6.447,6.447,0,0,1-1.7-4.41v-.975A.375.375,0,0,1,10.875,12h.75A.375.375,0,0,1,12,12.375Zm5.672-7.453L13.083.328a1.124,1.124,0,0,0-.8-.328H12V6h6V5.714A1.121,1.121,0,0,0,17.672,4.922Z" transform="translate(1811.102 100.657)" fill="#fff" :stroke="color" stroke-width="2"/>
    <rect id="Rechteck_1257" data-name="Rechteck 1257" width="15" height="12" transform="translate(1813 109)" fill="#fff"/>
    <text id="csv" transform="translate(1813 118)" :fill="color" font-size="10" class="text" font-weight="700"><tspan x="0" y="0">csv</tspan></text>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#FFF',
    },
  },
};
</script>

<style scoped>
.text {
  font-family: FSMatthew, FS Matthew;
}
</style>
